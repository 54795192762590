@import "../utils/style.scss";

.wrapper {
  padding: 0.5rem 0 0 0;
  position: relative;
}

.frame {
  &-divider {
    margin: 0.5rem 0.25rem 0 1rem;
    width: calc(100% - 1.25rem);
    border-bottom: 0.5px solid $col-disabled;
  }
  &-no-divider {
    margin: 0.5rem 0.25rem 0 1rem;
    width: calc(100% - 1.25rem);
  }
  &-toggle {
    position: absolute;
    left: 0;
    width: 1rem;
    height: 2rem;
    cursor: pointer;
    transition: transform 0.05s;
    &.open {
      transform: rotate(90deg);
    }
  }
}
