@import "../utils/style.scss";

.wrapper {
  display: flex;
  position: absolute;
  border-radius: 0.5rem;
  white-space: nowrap;
  border: $brd-light;
  @include txt-property-name;
  @include small-popup-shadow;
  z-index: 100;
  &.left {
    left: -0.5rem;
    top: 50%;
    transform: translate(-100%, -50%);
  }
  &.left-top {
    left: -0.5rem;
    top: 0;
    transform: translate(-100%, 0);
  }
  &.right {
    right: -0.5rem;
    top: 50%;
    transform: translate(100%, -50%);
  }
  &.top {
    top: -0.375rem;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  &.bottom {
    bottom: -0.25rem;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  &.bottom-right {
    bottom: -0.25rem;
    right: 0;
    transform: translate(0, 100%);
  }
  &.dark {
    background-color: $col-p;
    color: $col-back;
  }
  &.light {
    background-color: $col-back;
    color: $col-p;
  }
}

.commands {
  @include txt-property-name;
  font-weight: bold;
  color: $col-s;
  &.first {
    color: $col-disabled;
  }
}
