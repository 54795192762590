@import "../utils/style.scss";

.wrapper {
  width: 100%;
  height: 100%;
}

.frame {
  &-top {
    height: 2rem;
  }
  &-bottom {
    position: relative;
    padding: 0 0.5rem 0.25rem 0.5rem;
    width: calc(100% - 1rem);
    z-index: 2;
  }
  &-command-line {
    background-color: $col-back;
    border-radius: 2px;
    outline: 0.5px solid $col-disabled;
    outline-offset: -0.5px;
    width: 100%;
    height: 28px;
    @include small-popup-shadow;
  }
  &-command-symbol {
    min-width: 2rem;
    height: 100%;
    border-right: 0.5px solid $col-disabled;
    cursor: pointer;
    &:hover {
      background-color: $col-hover;
    }
  }
  &-command-text {
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    @include txt-property-name;
  }
  &-command-done {
    min-width: 4rem;
    height: 100%;
    cursor: pointer;
    border-left: 0.5px solid $col-disabled;
    @include txt-property-name;
    font-weight: 700;
    &:hover {
      background-color: $col-hover;
    }
  }
  &-option {
    cursor: pointer;
    height: 100%;
    padding: 0 0.25rem;
    &:hover {
      background-color: $col-hover;
    }
  }
}

.btn {
  &-command {
    position: relative;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    &:hover {
      background-color: $col-back;
    }
  }
}

.txt {
  &-highlight {
    color: $col-highlight;
  }
  &-command {
    color: $col-block;
    text-decoration: underline;
    cursor: pointer;
  }
}
