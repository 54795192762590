@import "../utils/style.scss";

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:hover {
    & .custom-scrollbar {
      width: 4px;
      background: rgba(100, 100, 100, 0.15);
      &:hover,
      &.dragging {
        width: 8px;
        background: rgba(100, 100, 100, 0.25);
      }
    }
  }
}

/* 커스텀 스크롤바 */
.custom-scrollbar {
  position: absolute;
  right: 4px;
  transition: width 0.1s;
  border-radius: 8px;
}

.auto-scroll-container {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* 브라우저 기본 스크롤 사용 */
  // padding-right: 20px; /* 스크롤바 공간을 위해 패딩 추가 */
  // border: 1px solid #ddd;
  // border-radius: 8px;
}

/* 기본 스크롤바 숨기기 (선택 사항) */
.auto-scroll-container::-webkit-scrollbar {
  display: none;
}

.auto-scroll-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
