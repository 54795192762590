@import "../utils/style.scss";

.wrapper {
  width: 15rem;
  min-width: 15rem;
  height: 100%;
  border-right: $brd-light;
}

.frame {
  width: 100%;
  height: 100%;
  background-color: $col-p;
}
