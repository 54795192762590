@import "../utils/style.scss";

.wrapper {
  position: absolute;
  background-color: $col-p;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  white-space: nowrap;
  color: $col-back;
  @include txt-property-name;
  @include small-popup-shadow;
  z-index: 100;
  &.right {
    right: -0.5rem;
    top: 50%;
    transform: translate(100%, -50%);
  }
  &.top {
    top: -0.375rem;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.commands {
  @include txt-property-name;
  font-weight: bold;
  color: $col-s;
  &.first {
    color: $col-disabled;
  }
}
