li {
  list-style: none;
  padding-left: 0px;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  border-width: 0;
  border-style: none;
  cursor: pointer;
}
button:active {
  border-style: none;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  background-color: rgba(255, 255, 255, 0);
  min-width: 0;
}
input:focus {
  border-style: none;
  outline: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}
p {
  margin: 0px;
}
h1 {
  margin: 0px;
}
h2 {
  margin: 0px;
}
h3 {
  margin: 0px;
  font-weight: 400;
}
body {
  font-size: 16px;
  font-family: "Noto Sans KR";
  line-height: 1;
}
html {
  font-size: 16px;
  box-sizing: border-box;
}
input,
select,
textarea,
button,
a,
label,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
textarea:active,
textarea:focus-visible,
textarea:focus {
  outline: none;
}

body.survey,
html.survey {
  overscroll-behavior: contain;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  transition: background-color 1s ease;
  -webkit-overflow-scrolling: touch;
  background-color: #eed9c8;
}

sup {
  font-size: 0.75em; /* Adjust the font size to be smaller */
  line-height: 0; /* Adjust line height to prevent height change */
}
