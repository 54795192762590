@import "../utils/style.scss";

.hide {
  opacity: 0;
}

.txt {
  &-bold {
    @include txt-section-title;
    color: $col-p;
  }
  &-regular {
    @include txt-property-value;
  }
  &-left {
    text-align: left;
  }
}

.group-title {
  padding: 0 0.5rem;
  width: calc(100% - 1rem);
}

.subgroup {
  @include txt-property-name;
  &.bullet {
    width: 0.5rem;
  }
  &.txt {
    width: 100%;
  }
  &.overwritten {
    color: $col-changed;
  }
}

.property-name-group {
  padding: 0 0.5rem;

  @include txt-property-name-small;
}

.object-input {
  box-sizing: border-box;
  border-radius: 0.125rem;
  &.inactivated {
    pointer-events: none;
  }
  &:hover {
    outline: 2px solid $col-hover;
    outline-offset: -2px;
  }
  &:focus-within {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
}

.frame {
  &-action {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        background-color: $col-hover;
      }
    }
    &.clicked {
      background-color: $col-highlight;
      & svg [stroke] {
        stroke: $col-back;
      }
      & svg [fill] {
        fill: $col-back;
      }
    }
    &.disabled {
      & svg [stroke] {
        stroke: $col-disabled;
      }
      & svg [fill] {
        fill: $col-disabled;
      }
    }
  }

  &-input {
    width: 100%;
    height: 100%;
    @include txt-property-value;
    color: $col-p;
    &::placeholder {
      color: $col-p;
    }
    &:focus::placeholder {
      color: $col-disabled;
    }
    &.disabled {
      color: $col-disabled;
      &::placeholder {
        color: $col-disabled;
      }
    }
  }
  &-dropdown {
    width: 1rem;
    height: 2rem;
    & svg {
      transform: rotate(90deg);
      & * {
        stroke: $col-disabled;
      }
    }
  }
}

.text-input,
.text-selection {
  &-fill {
    height: 2rem;
    &:hover {
      outline: 2px solid $col-hover;
      outline-offset: -2px;
    }
    &:focus-within {
      outline: 2px solid $col-highlight;
      outline-offset: -2px;
    }
    & .frame-name {
      padding: 0 0.5rem;
      width: 5rem;
      @include txt-property-name;
      color: $col-s;
    }
    & .frame-value {
      padding: 0 0.5rem;
      width: 5rem;
      color: $col-p;
      &.fit {
        padding: 0 0 0 0.5rem;
        width: min-content;
      }
    }
    &:hover {
      & .fit {
        width: 4rem;
      }
    }
  }
}

.text-selection {
  &-fill {
    cursor: pointer;
    // padding-right: 0.5rem;
  }
}

.only-selection {
  width: 100%;
  max-width: 11.5rem;
  cursor: pointer;

  border-radius: 0.125rem;
  &:hover {
    outline: 2px solid $col-hover;
    outline-offset: -2px;
    & .frame-value {
      width: 100%;
    }
  }
  &:focus-within {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
  & .frame-value {
    padding: 0 0 0 0.5rem;
    color: $col-p;
  }
}

.object-selection,
.object {
  width: 100%;
  min-width: 0;
  max-width: 12rem;
  cursor: pointer;
  position: relative;
  border-radius: 0.125rem;
  &:hover {
    outline: 2px solid $col-hover;
    outline-offset: -2px;
    & .frame-value {
      flex-grow: 1;
    }
  }
  &:focus-within {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
  & .frame-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $col-p;
  }
  &.clicked {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
  & .illust {
    color: $col-s;
  }
}

.text-input,
.object-input {
  width: 100%;
  max-width: 11.5rem;
  cursor: pointer;
  padding-right: 0.5rem;
  border-radius: 0.125rem;
  @include txt-property-name;
  color: $col-s;
  &:hover {
    outline: 2px solid $col-hover;
    outline-offset: -2px;
    & .frame-value {
      width: 100%;
    }
  }
  &:focus-within {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
  & .frame-value {
    // padding: 0 0 0 0.5rem;
    color: $col-p;
  }
}

.long-text,
.long-selection {
  height: 2rem;
  width: 100%;
  &:hover {
    outline: 2px solid $col-hover;
    outline-offset: -2px;
  }
  &:focus-within {
    outline: 2px solid $col-highlight;
    outline-offset: -2px;
  }
  & .frame-name {
    padding: 0 0.5rem;
    white-space: nowrap;
    @include txt-property-name;
    color: $col-s;
  }
  & .frame-value {
    padding: 0 0.5rem 0 0;
    width: 100%;
    color: $col-p;
    &.fit {
      padding: 0;
      width: min-content;
    }
  }
  &:hover {
    & .fit {
      width: 100%;
    }
  }
}

.long-selection {
  cursor: pointer;
}

.frame-dropdown {
  padding-right: 0.5rem;
}

.overwritten {
  & * {
    color: $col-changed !important;
  }
}

.frame-overwritten {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 18px;
  border-radius: 1px;
  border: solid 0.5px $col-changed;
  color: $col-changed;
  @include txt-button-small;
}
