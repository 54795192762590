@import "../utils/style.scss";

.wrapper {
  color: $col-p;
  cursor: default;
  @include txt-property-value;
  &::placeholder {
    color: $col-p;
  }
  &::selection {
    color: $col-p;
    background-color: $col-selection;
  }
  &::-moz-selection {
    color: $col-p;
    background-color: $col-selection;
  }
}
