@import "../utils/style.scss";

.wrapper {
  background-color: $col-p;
  padding: 0 1rem;
  width: calc(100% - 2rem);
  height: 1.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  color: $col-back;
  @include txt-property-name;
  cursor: pointer;
  &:hover {
    background-color: $col-highlight;
  }
}

.commands {
  @include txt-property-name;
  font-weight: bold;
  color: $col-s;
  &.first {
    color: $col-disabled;
  }
}
