@import "../utils/style.scss";

.wrapper {
  width: 100%;
  height: 100%;
}

.frame {
  width: 100%;
  height: 100%;
  background-color: $col-p;
  &-bottom {
    min-height: 64px;
    width: 100%;
  }
  &-breadscrum {
    padding: 0.25rem;
    @include txt-property-value;
    color: $col-s;
    &:not(:last-child) {
      text-decoration: underline;
      border-radius: 0.25rem;
      cursor: pointer;
      &:hover {
        background-color: $col-back;
      }
    }
    &:last-child {
      color: $col-p;
    }
  }
}

.seperator {
  @include txt-property-value;
  color: $col-s;
}

.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: url("/imgs/cursor_default.png") 5 4, default;
  & > * {
    position: absolute;
  }
  &.command {
    cursor: url("/imgs/cursor_draw@2.png") 30 30, crosshair;
  }
  &.dragging:active {
    cursor: url("/imgs/cursor_grab.png") 6 6, grab;
  }
}
