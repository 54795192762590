@import "../utils/style.scss";

.wrapper {
  position: absolute;
  top: -6px;
  transform: translate(0, -100%);
  width: 15rem;
  @include small-popup-shadow;
}

.frame {
  &-command-line {
    background-color: $col-back;

    outline: 0.5px solid $col-disabled;
    outline-offset: -0.5px;
    width: 100%;
    height: 28px;
    cursor: pointer;
    &:hover {
      background-color: $col-hover;
    }
    &:first-child {
      border-radius: 2px 2px 0 0;
    }
    &:last-child {
      border-radius: 0 0 2px 2px;
    }
    &.active {
      background-color: $col-hover;
    }
  }
  &-command-symbol {
    min-width: 2rem;
    height: 100%;
    border-right: 0.5px solid $col-disabled;
  }
  &-command-text {
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    @include txt-property-name;
  }
}

.commands {
  @include txt-property-name;
  font-weight: bold;
  color: $col-disabled;
  &.first {
    color: $col-s;
  }
}
