@import "../utils/style.scss";

.wrapper {
  width: 14rem;
}

.frame {
  &-search-input {
    margin: 0;
    padding: 0;
    width: 12rem;
    @include txt-property-value;
    font-weight: medium;
    color: $col-p;
    &::placeholder {
      @include txt-property-value;
      color: $col-disabled;
    }
  }
  &-object {
    // background-color: $col-p;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    height: 2rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    color: $col-back;
    @include txt-property-name;
    cursor: pointer;
    &:hover {
      background-color: $col-hover;
    }
  }

  &-divider {
    margin: 0.25rem 0;
    width: 100%;
    border-bottom: 0.5px solid $col-disabled;
  }
  &-color {
    width: 1.125rem;
    height: 1.125rem;
  }
}
