@import "../utils/style.scss";

.wrapper {
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    background-color: $col-hover;
  }
}

.frame {
  &-icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    font-weight: 800;
  }
}

.text-name {
  padding-right: 0.5rem;
  @include txt-section-title;
}
