@import "../utils/style.scss";

.wrapper {
  position: relative;
  cursor: pointer;
  &.clicked {
    background-color: $col-back;
    cursor: auto;
  }
}

.frame {
  &-selection {
    position: absolute;
    width: 0.375rem;
    height: 2rem;
    &.clicked {
      background-color: $col-highlight;
    }
  }
  &-color {
    width: 1rem;
    height: 0.65rem;
    border: 1px solid $col-p;
    cursor: pointer;
  }
}
