@import "../utils/style.scss";

.wrapper {
  width: 15rem;
  min-width: 15rem;
  height: 100%;
  border-left: $brd-light;
  background-color: $col-back;
}

.frame {
  width: 100%;
  height: 100%;
  background-color: $col-p;
  &-color {
    width: 1.125rem;
    height: 1.125rem;
  }
}
