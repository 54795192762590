@import "../utils/reset.css";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400..700&display=swap");

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
      format("opentype");
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: "Noto Sans KR";
  font-display: swap;
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
  // unicode-range: U+AC00-D7A3;
}

$font-l: 200;
$font-sr: 400;
$font-r: 500;
$font-b: 700;
$font-e: 900;

body {
  // font-family: "Nanum Gothic", sans-serif;
  // font-family: "KoPubDotumMedium";
  // font-family: "NanumSquare";
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", "Noto Sans";
  font-weight: $font-r;
  &::-webkit-scrollbar {
    display: none;
  }
}

input {
  font-family: "Noto Sans KR", "Noto Sans";
}

$col-back: #f5f5f5;

$col-p: #404040;
$col-s: #909090;
$col-hover: #e8e8e8;
$col-disabled: #c0c0c0;
$col-w: #ffffff;

$col-highlight: #0d99ff;
$col-changed: #ff0000;
$col-block: #9747ff;
$col-selection: #98d0f9;

$ipad-width: 767px; // ipad mini width === 768
$min-slide-height: 900px;
$min-card-width: 280px;
$max-card-width: 300px;
$max-card-height: 300px;

@mixin no-drag {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@mixin txt-logo {
  font-family: "GmarketSans", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
@mixin txt-logo-eng {
  font-family: "Reem Kufi", sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
  @media screen and (max-width: $ipad-width) {
    font-size: 1rem;
    line-height: 1;
  }
}
@mixin txt-property-name {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
@mixin txt-property-value {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}
@mixin txt-section-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
}
@mixin txt-property-name-small {
  font-family: "Noto Sans", sans-serif;
  font-weight: medium;
  font-size: 10px;
  line-height: 10px;
  color: $col-s;
}
@mixin txt-button-small {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

$brd-light: 0.5px solid $col-disabled;

$shd-1: 0 0 5px rgba(0, 0, 0, 0.25);
$shd-w: 0 0 2px rgba(255, 255, 255, 1);

$shd-border-w: -1px 0 2px #fff, 0 1px 2px #fff, 1px 0 2px #fff, 0 -1px 2px #fff;

@mixin shd-1 {
  box-shadow: 0 0 0.25rem $col-shd-b;
}

@mixin small-popup-shadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
}

@mixin column($w, $h, $gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  align-items: center;
  box-sizing: content-box;
  // box-sizing: border-box;
  > * {
    width: $w;
    height: $h;
    // text-align: center;
  }
}

@mixin row($w, $h, $gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
  align-items: center;
  box-sizing: content-box;
  // box-sizing: border-box;
  > * {
    width: $w;
    height: $h;
  }
}

@mixin box($w, $h, $r, $col-border, $col-fill) {
  box-sizing: border-box;
  color: $col-txt-b;
  width: $w;
  height: $h;
  background: $col-fill;
  border: solid $col-border 1px;
  border-radius: $r;
}
