@import "../utils/style.scss";

.btn {
  &-nav {
    position: relative;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    &:hover {
      background-color: $col-hover;
    }
    &.focus {
      background-color: $col-p;
      & svg [stroke] {
        stroke: $col-back;
      }
      & svg [fill] {
        fill: $col-back;
      }
    }
  }
}
