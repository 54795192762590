@import "../utils/style.scss";

.wrapper {
  width: 100vw;
  height: 100vh;
}

.frame {
  &-nav {
    background-color: $col-back;
    height: 100%;
    border-right: $brd-light;
  }
}
