@import "../utils/style.scss";

.wrapper {
  box-sizing: border-box;
  &.horizontal {
    width: 100%;
    padding: 0 0.25rem;
  }
  &.vertical {
    height: 100%;
    // padding: ;
  }
}

.frame {
  width: 100%;
  height: 100%;
  background-color: $col-p;
}
